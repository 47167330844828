import React from "react";
import innovationLogo from "../../../Icons/InnovationIcon.svg";
import powerLogo from "../../../Icons/Power Icon.svg";
import digitalLogo from "../../../Icons/Digital Solutions.svg";
import { motion } from "framer-motion";
// Feature Data
export const featuresData = [
  {
    iconSrc: innovationLogo,
    title: "Innovation",
    description:
      "We embrace a culture of continuous innovation by exploring emerging technologies and methodologies. Our solutions are designed to anticipate future trends, ensuring that businesses can stay ahead of the curve and thrive in a competitive landscape.",
  },
  {
    iconSrc: digitalLogo,
    title: "Digital Optimization",
    description:
      "Our digital optimization services help businesses streamline processes by integrating smart technologies and data-driven strategies. We focus on improving operational efficiency, reducing overheads, and delivering seamless customer experiences across all digital channels.",
  },
  {
    iconSrc: powerLogo,
    title: "Advanced Software Solutions",
    description:
      "We specialize in developing custom, high-performance software that aligns with your business objectives. From web applications to enterprise systems, our solutions are scalable, reliable, and designed to evolve with your business needs.",
  },
];
const Features = () => {
  return (
    <section className="features">
      {featuresData.map((feature, index) => (
        <motion.div
          key={index}
          className="feature-card"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: index * 0.3 }}
        >
          <img
            src={feature.iconSrc}
            alt={feature.title}
            className="feature-card-icon"
          />
          <div className="rounded-title-card">
            <span className="rounded-title-text">{feature.title}</span>
          </div>
          <p className="feature-card-description">{feature.description}</p>
        </motion.div>
      ))}
    </section>
  );
};

export default Features;
