import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";


const AonoLabs = () => {
  return (
    <Routes>
      {/* Route for Homepage (with NewsFeed, Hero, Features, and Footer) */}
      <Route path="/" element={<Home />} />

      {/* Route for Individual Post Details */}
    </Routes>
  );
};

export default AonoLabs;
