import React from "react";
import { motion } from "framer-motion";
import Features from "./Features"; // Features component
import Footer from "./Footer"; // Footer component
import { Container } from "@mui/material";
import JobListings from "./JobListings";

const Home = () => {
  return (
    <div className="app-container">
      {/* Hero Section */}
      <Container sx={{ py: 0, textAlign: "center" }}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="header-section">
            <h2 className="header-title">AONO Labs</h2>

            <h4 className="subheader">Redefining the Digital Landscape</h4>
          </div>
        </motion.div>
      </Container>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h4 className="header-subtitle">Thrive with Smart Solutions</h4>
        <Features />
        <br /> <br /> <br /> <br />
        <JobListings />
        <br /> <br />
        <h2 className="header-subtitle">
          Have a Project in Mind? Let&apos;s Talk
        </h2>
        <div className="form-container">
          <input
            type="email"
            className="input-email"
            placeholder="Enter your email"
            required
          />
          <div className="btn-primary">Subscribe</div>
        </div>
      </motion.div>
      <br /> <br /> <br /> <br />
      <Footer />
    </div>
  );
};

export default Home;
