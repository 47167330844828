import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

const jobs = [
  {
    title: "Frontend Developer",
    location: "Remote",
    type: "Part-Time",
    description:
      "Bring sleek designs to life with your React.js and CSS expertise. Let’s create fast, user-friendly web experiences together.",
  },
  {
    title: "UX/UI Designer",
    location: "Remote",
    type: "Part-Time",
    description:
      "Turn ideas into intuitive, beautiful interfaces. Your designs will shape how users experience our brand.",
  },
  {
    title: "Project Manager",
    location: "Remote",
    type: "Contract",
    description:
      "Lead teams, manage timelines, and deliver results that matter. Drive our projects to success with precision and passion.",
  },
];

const JobListings = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20%", // Ensures no padding issues
    focusOnSelect: true,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <section className="job-listings-carousel">
      <h2 className="header-subtitle">Build the Future with Us</h2>
      <Slider {...settings}>
        {jobs.map((job, index) => (
          <div key={index} className="job-card-carousel">
            <h3 className="job-title">{job.title}</h3>
            <div className="rounded-title-card">
              <span className="rounded-title-text">{job.location}</span>
            </div>
            <p className="job-type">
              {/* <strong>Type:</strong> {job.type} */}
            </p>
            <p className="job-description">{job.description}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default JobListings;
